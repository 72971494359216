import React from "react"
import Layout from "../components/layout"
import { createUseStyles } from 'react-jss'
import { graphql } from "gatsby"
import SEO from "../components/seo"

const useStyles = createUseStyles({
    layout: {
      'padding': '1rem',
    },
    title: {
      'margin-bottom': '0.5rem'
    },
    date: {
      'font-weight': 'normal'
    },
    content: {
        padding: 0,
        'margin-left': 'auto',
        'margin-right': 'auto',
        'max-width': '52.5rem',
        '& blockquote': {
        'border-left': '5px solid #ccc',
        'padding': '0.5em 10px'
        },
        '& a': {
            color: 'white'
        },
        '& pre': {
            padding: '15px',
            overflowX: 'scroll',
            background: '#333333',
        }
  }
})

// NOTE: Should we really use dangerouslySetInnerHTML for article posts?
export default function BlogPost({ data }) {
    const post = data.markdownRemark
    const classes = useStyles()
    return (
        <Layout style={classes.layout}>
            <SEO title={post.frontmatter.title} description={post.excerpt} />
            <div>
                <div className={classes.content}>
                    <h1>{post.frontmatter.title}</h1>
                    <h4 className={classes.date}>{post.frontmatter.date} - {post.frontmatter.tags}</h4>
                </div>
                <div className={classes.content} dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        tags
      }
      excerpt
    }
  }
`
